define("ember-credit-card/templates/components/credit-card", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = Ember.HTMLBars.template({
    "id": "X7RhF3Oe",
    "block": "[[[41,[33,1],[[[1,\"\\t\"],[10,0],[14,0,\"card-wrapper\"],[12],[13],[1,\"\\n\\t\"],[10,\"br\"],[12],[13],[1,\"\\n\"]],[]],null],[41,[48,[30,1]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"\\t\"],[10,\"form\"],[14,1,\"ember-credit-card-form\"],[12],[1,\"\\n\\t\\t\"],[1,[28,[35,4],null,[[\"type\",\"name\",\"value\",\"placeholder\"],[\"text\",\"number\",[33,5],\"Credit Card Number\"]]]],[1,\"\\n\\t\\t\"],[1,[28,[35,4],null,[[\"type\",\"name\",\"value\",\"placeholder\"],[\"text\",\"name\",[33,6],\"Name on Credit Card\"]]]],[1,\"\\n\\t\\t\"],[1,[28,[35,4],null,[[\"type\",\"name\",\"value\"],[\"text\",\"expiry\",[33,7]]]]],[1,\"\\n\\t\\t\"],[1,[28,[35,4],null,[[\"type\",\"name\",\"value\"],[\"text\",\"cvc\",[33,8]]]]],[1,\"\\n\\t\"],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"if\",\"showCard\",\"has-block\",\"yield\",\"input\",\"number\",\"name\",\"expiry\",\"cvc\"]]",
    "moduleName": "ember-credit-card/templates/components/credit-card.hbs",
    "isStrictMode": false
  });
});