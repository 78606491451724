define("ember-model-validator/initializers/register-version", ["exports", "ember"], function (_exports, _ember) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize() {
    _ember.default.libraries.register('Ember Model Validator', '3.11.0');
  }
  var _default = _exports.default = {
    initialize
  };
});