define("ember-paper/utils/key-constants", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.UP_ARROW = _exports.TAB = _exports.SPACE = _exports.RIGHT_ARROW = _exports.LEFT_ARROW = _exports.ESCAPE = _exports.ENTER = _exports.DOWN_ARROW = void 0;
  const ENTER = _exports.ENTER = 13;
  const ESCAPE = _exports.ESCAPE = 27;
  const SPACE = _exports.SPACE = 32;
  const LEFT_ARROW = _exports.LEFT_ARROW = 37;
  const UP_ARROW = _exports.UP_ARROW = 38;
  const RIGHT_ARROW = _exports.RIGHT_ARROW = 39;
  const DOWN_ARROW = _exports.DOWN_ARROW = 40;
  const TAB = _exports.TAB = 9;
});