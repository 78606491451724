define("ember-paper/components/paper-progress-linear", ["exports", "@ember/service", "@ember/object", "@ember/component", "@ember/utils", "@ember/string", "ember-paper/templates/components/paper-progress-linear", "ember-paper/mixins/color-mixin"], function (_exports, _service, _object, _component, _utils, _string, _paperProgressLinear, _colorMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */

  function makeTransform(value) {
    let scale = value / 100;
    let translateX = (value - 100) / 2;
    return `translateX(${translateX.toString()}%) scale(${scale.toString()}, 1)`;
  }
  const MODE_DETERMINATE = 'determinate';
  const MODE_INDETERMINATE = 'indeterminate';
  const MODE_BUFFER = 'buffer';
  const MODE_QUERY = 'query';

  /**
   * @class PaperProgressLinear
   * @extends Ember.Component
   * @uses ColorMixin
   */
  var _default = _exports.default = _component.default.extend(_colorMixin.default, {
    layout: _paperProgressLinear.default,
    tagName: 'md-progress-linear',
    attributeBindings: ['mode:md-mode', 'bufferValue:md-buffer-value'],
    classNames: ['md-default-theme'],
    constants: (0, _service.inject)(),
    mode: (0, _object.computed)('value', {
      get() {
        if (this._mode !== undefined) {
          return this._mode;
        }
        let value = this.value;
        let bufferValue = this.bufferValue;
        if ((0, _utils.isPresent)(value)) {
          if ((0, _utils.isPresent)(bufferValue)) {
            return MODE_BUFFER;
          } else {
            return MODE_DETERMINATE;
          }
        } else {
          return MODE_INDETERMINATE;
        }
      },
      set(key, value) {
        return this._mode = value;
      }
    }),
    queryModeClass: (0, _object.computed)('mode', function () {
      let mode = this.mode;
      if ([MODE_QUERY, MODE_BUFFER, MODE_DETERMINATE, MODE_INDETERMINATE].includes(mode)) {
        return `md-mode-${mode}`;
      } else {
        return '';
      }
    }),
    bar1Style: (0, _object.computed)('clampedBufferValue', function () {
      return (0, _string.htmlSafe)(`${this.get('constants.CSS.TRANSFORM')}: ${makeTransform(this.clampedBufferValue)}`);
    }),
    bar2Style: (0, _object.computed)('clampedValue', 'mode', function () {
      if (this.mode === MODE_QUERY) {
        return (0, _string.htmlSafe)('');
      }
      return (0, _string.htmlSafe)(`${this.get('constants.CSS.TRANSFORM')}: ${makeTransform(this.clampedValue)}`);
    }),
    clampedValue: (0, _object.computed)('value', function () {
      let value = this.value;
      return Math.max(0, Math.min(value || 0, 100));
    }),
    clampedBufferValue: (0, _object.computed)('bufferValue', function () {
      let value = this.bufferValue;
      return Math.max(0, Math.min(value || 0, 100));
    })
  });
});