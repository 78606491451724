define("ember-gridstack/services/grid-stack-registry", ["exports", "@ember/service", "@ember/debug"], function (_exports, _service, _debug) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  class GridstackService extends _service.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "_grids", {});
    }
    /**
     * Registers a GridStack grid
     * @param {string} id - the string id of the GridStack element
     * @param {GridStack} grid - A Gridstack instance
     * @returns {void}
     */
    registerGrid(id, grid) {
      (false && !(!(id in this._grids)) && (0, _debug.assert)('grid should not be already registered', !(id in this._grids)));
      this._grids[id] = grid;
    }

    /**
     * Finds the closest GridStack
     * @param {string} id - the string id of the GridStack element
     * @returns {GridStack|undefined}
     */
    findGridComponent(element) {
      const gridStack = element.closest('.grid-stack');
      if (!gridStack) {
        return undefined;
      }
      const {
        id
      } = gridStack;
      (false && !(id) && (0, _debug.assert)('The grid should have an id', id));
      const grid = this._grids[id];
      (false && !(grid) && (0, _debug.assert)(`The grid for ${id} should be registered`, grid));
      return grid;
    }

    /**
     * Removes the registry for a grid
     * @param {string} id - the string id of the GridStack element
     * @returns {void}
     */
    unregisterGridComponent(id) {
      delete this._grids[id];
    }
  }
  _exports.default = GridstackService;
});