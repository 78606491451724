define("ember-paper/components/paper-tooltip-inner", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "ember-paper/templates/components/paper-tooltip-inner", "ember-css-transitions/utils/transition-utils", "ember-paper/utils/calculate-tooltip-position"], function (_exports, _component, _object, _runloop, _paperTooltipInner, _transitionUtils, _calculateTooltipPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = _component.default.extend({
    layout: _paperTooltipInner.default,
    tagName: '',
    positionClass: (0, _object.computed)('position', function () {
      return `md-origin-${this.position}`;
    }),
    setupTooltip(element, [position, anchorElement]) {
      _runloop.run.schedule('afterRender', () => {
        let pos = (0, _calculateTooltipPosition.default)(element, anchorElement, position);
        element.style.top = `${pos.top}px`;
        element.style.left = `${pos.left}px`;
        element.classList.add('md-show-add');
        (0, _transitionUtils.nextTick)().then(_transitionUtils.nextTick).then(_transitionUtils.nextTick).then(_transitionUtils.nextTick).then(() => {
          element.classList.add('md-show');
        });
      });
    }
  });
});