define("ember-paper/components/paper-toolbar-tools", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */
  /**
   * @class PaperToolbarTools
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    classNames: ['md-toolbar-tools']
  });
});