define("ember-paper/templates/components/paper-toast-inner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "tvCvY25m",
    "block": "[[[11,\"md-toast\"],[24,5,\"transform:translate(0px, 0)\"],[16,0,[29,[[52,[30,1],\"md-left\",\"md-right\"],\" \",[52,[30,2],\"md-top\",\"md-bottom\"],\" \",[52,[30,3],\"md-capsule\"],\" \",[30,4]]]],[17,5],[4,[38,1],null,[[\"enterClass\",\"enterActiveClass\",\"enterToClass\",\"leaveClass\",\"leaveActiveClass\",\"leaveToClass\"],[\"ng-enter\",\"ng-enter\",\"ng-enter-active\",\"ng-dummyclass\",\"ng-leave\",\"ng-leave-active\"]]],[4,[38,2],null,[[\"enabled\",\"onClose\"],[[30,6],[30,7]]]],[12],[1,\"\\n  \"],[10,0],[14,0,\"md-toast-content\"],[12],[1,\"\\n    \"],[18,8,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"@left\",\"@top\",\"@capsule\",\"@class\",\"&attrs\",\"@swipeToClose\",\"@onClose\",\"&default\"],false,[\"if\",\"css-transition\",\"toast-hammer\",\"yield\"]]",
    "moduleName": "ember-paper/templates/components/paper-toast-inner.hbs",
    "isStrictMode": false
  });
});