define("ember-paper/components/paper-contact-chips/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "9L04fz/I",
    "block": "[[[11,\"md-contact-chips\"],[16,0,[29,[\"md-default-theme \",[30,1]]]],[17,2],[12],[1,\"\\n  \"],[8,[39,0],[[24,0,\"md-contact-chips\"]],[[\"@readOnly\",\"@addItem\",\"@removeItem\",\"@requireMatch\",\"@searchField\",\"@content\",\"@options\",\"@defaultHighlighted\",\"@placeholder\",\"@search\",\"@matcher\",\"@noMatchesMessage\"],[[30,3],[30,4],[30,5],true,[30,0,[\"searchField\"]],[30,6],[30,7],[30,8],[30,9],[30,10],[30,11],[30,12]]],[[\"default\"],[[[[1,\"\\n\\n\"],[41,[28,[37,2],[[30,14],\"suggestion\"],null],[[[1,\"      \"],[10,0],[14,0,\"md-contact-suggestion\"],[12],[1,\"\\n        \"],[10,\"img\"],[15,\"src\",[28,[37,3],[[30,13],[30,0,[\"imageField\"]]],null]],[15,\"alt\",[28,[37,3],[[30,13],[30,0,[\"nameField\"]]],null]],[14,0,\"md-contact-avatar\"],[12],[13],[1,\"\\n        \"],[10,1],[14,0,\"md-contact-name\"],[12],[1,[28,[35,3],[[30,13],[30,0,[\"nameField\"]]],null]],[13],[1,\"\\n        \"],[10,1],[14,0,\"md-contact-email\"],[12],[1,[28,[35,3],[[30,13],[30,0,[\"emailField\"]]],null]],[13],[1,\"\\n      \"],[13],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[41,[28,[37,2],[[30,14],\"chip\"],null],[[[1,\"      \"],[10,0],[14,0,\"md-contact-avatar\"],[12],[1,\"\\n        \"],[10,\"img\"],[15,\"src\",[28,[37,3],[[30,13],[33,4]],null]],[15,\"alt\",[28,[37,3],[[30,13],[33,5]],null]],[12],[13],[1,\"\\n      \"],[13],[1,\"\\n      \"],[10,1],[14,0,\"md-contact-name\"],[12],[1,[28,[35,3],[[30,13],[33,5]],null]],[13],[1,\"\\n\"]],[]],null],[1,\"\\n  \"]],[13,14]]]]],[1,\"\\n\"],[13]],[\"@class\",\"&attrs\",\"@readOnly\",\"@addItem\",\"@removeItem\",\"@content\",\"@options\",\"@defaultHighlighted\",\"@placeholder\",\"@search\",\"@matcher\",\"@noMatchesMessage\",\"item\",\"type\"],false,[\"paper-chips\",\"if\",\"eq\",\"get\",\"imageField\",\"nameField\"]]",
    "moduleName": "ember-paper/components/paper-contact-chips/template.hbs",
    "isStrictMode": false
  });
});