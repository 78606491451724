define("ember-paper/components/paper-select/option/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "ANQDmOwE",
    "block": "[[[11,\"md-option\"],[17,1],[12],[1,\"\\n  \"],[10,0],[14,0,\"md-text\"],[12],[18,2,null],[13],[1,\"\\n  \"],[8,[39,1],null,null,null],[1,\"\\n\"],[13]],[\"&attrs\",\"&default\"],false,[\"yield\",\"paper-ripple\"]]",
    "moduleName": "ember-paper/components/paper-select/option/template.hbs",
    "isStrictMode": false
  });
});