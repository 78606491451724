define("ember-paper/services/paper-toaster", ["exports", "@ember/object/computed", "@ember/polyfills", "@ember/runloop", "@ember/array", "@ember/service", "@ember/object", "ember-get-config"], function (_exports, _computed, _polyfills, _runloop, _array, _service, _object, _emberGetConfig) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const DEFAULT_PROPS = {
    duration: 3000,
    position: 'bottom left'
  };
  var _default = _exports.default = _service.default.extend({
    queue: (0, _array.A)(),
    activeToast: (0, _computed.reads)('queue.firstObject'),
    show(text, options) {
      let t = _object.default.create((0, _polyfills.assign)({
        text,
        show: true
      }, this.buildOptions(options)));
      this.queue.pushObject(t);
      return t;
    },
    showComponent(componentName, options) {
      let t = _object.default.create((0, _polyfills.assign)({
        componentName,
        show: true
      }, this.buildOptions(options)));
      this.queue.pushObject(t);
      return t;
    },
    cancelToast(toast) {
      toast.set('show', false);
      if (this.activeToast === toast) {
        _runloop.run.later(() => {
          if (toast.onClose) {
            toast.onClose();
          }
          this.queue.removeObject(toast);
        }, 400);
      } else {
        if (toast.onClose) {
          toast.onClose();
        }
        this.queue.removeObject(toast);
      }
    },
    buildOptions(options) {
      let toasterOptions = {};
      if (_emberGetConfig.default['ember-paper'] && _emberGetConfig.default['ember-paper']['paper-toaster']) {
        toasterOptions = _emberGetConfig.default['ember-paper']['paper-toaster'];
      }
      return (0, _polyfills.assign)({}, DEFAULT_PROPS, toasterOptions, options);
    }
  });
});