define("ember-paper/mixins/proxiable-mixin", ["exports", "@ember/object/mixin", "@ember/runloop", "ember-composability-tools"], function (_exports, _mixin, _runloop, _emberComposabilityTools) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */
  /**
   * @class ProxiableMixin
   * @uses ChildMixin
   * @extends Ember.Mixin
   */
  var _default = _exports.default = _mixin.default.create(_emberComposabilityTools.ChildMixin, {
    classNameBindings: ['secondary:md-secondary'],
    shouldRegister: false,
    registerWithParent() {
      _runloop.run.next(this, this._super);
    },
    mouseDown() {
      this._super(...arguments);
      let parentComponent = this.parentComponent;
      if (parentComponent) {
        parentComponent.set('mouseActive', true);
        _runloop.run.later(() => {
          if (parentComponent.isDestroyed) {
            return;
          }
          parentComponent.set('mouseActive', false);
        }, 100);
      }
    },
    focusIn() {
      this._super(...arguments);
      let parentComponent = this.parentComponent;
      if (parentComponent && !parentComponent.get('mouseActive')) {
        parentComponent.set('focused', true);
      }
    },
    focusOut() {
      this._super(...arguments);
      let parentComponent = this.parentComponent;
      if (parentComponent) {
        parentComponent.set('focused', false);
      }
    }
  });
});