define("ember-paper/validators/max", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.max = max;
  /**
   * @module ember-paper
   */

  function max(value, max) {
    return (0, _utils.isEmpty)(max) || (0, _utils.isEmpty)(value) || parseFloat(value) <= parseFloat(max);
  }
  var _default = _exports.default = {
    param: 'max',
    message: 'Must be less than %@.',
    validate: max
  };
});