define("ember-gridstack/modifiers/gs-item-attributes", ["exports", "ember-modifier", "@ember/string", "@ember/utils"], function (_exports, _emberModifier, _string, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * Copyright 2021, Yahoo Inc.
   * Copyrights licensed under the BSD License. See the accompanying LICENSE file for terms.
   */

  const GS_PREFIX = 'gs-';
  var _default = _exports.default = (0, _emberModifier.modifier)(function itemAttributes(element, opt) {
    const [options = {}] = opt || [];
    Object.entries(options).forEach(([key, value]) => {
      const attribute = `${GS_PREFIX}${(0, _string.dasherize)(key)}`;
      if ((0, _utils.isBlank)(value)) {
        element.removeAttribute(attribute);
      } else {
        element.setAttribute(attribute, value);
      }
    });
  });
});