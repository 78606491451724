define("ember-model-validator/initializers/model-locale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.initialize = initialize;
  function initialize(application) {
    let validatorDefaultLocale = application.get('validatorDefaultLocale');
    if (validatorDefaultLocale) {
      application.register('validator:locale', {
        locale: validatorDefaultLocale
      }, {
        instantiate: false
      });
    }
  }
  var _default = _exports.default = {
    name: 'model-locale',
    initialize
  };
});