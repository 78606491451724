define("ember-paper/components/paper-autocomplete/highlight/component", ["exports", "@ember/component", "@ember/object", "ember-paper/components/paper-autocomplete/highlight/template", "@ember-decorators/component"], function (_exports, _component, _object, _template, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2;
  /**
   * @module ember-paper
   */
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  /**
   * @class PaperAutocompleteHighlight
   * @extends Ember.Component
   */
  let PaperAutocompleteHighlight = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _component2.layout)(_template.default), _dec3 = (0, _object.computed)('regex', 'label'), _dec4 = (0, _object.computed)('searchText', 'flags'), _dec(_class = _dec2(_class = (_class2 = class PaperAutocompleteHighlight extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "flags", '');
    }
    get tokens() {
      let string = `${this.label}`;
      let regex = this.regex;
      let tokens = [];
      let lastIndex = 0;

      // Use replace here, because it supports global and single regular expressions at same time.
      string.replace(regex, (match, index) => {
        let prev = string.slice(lastIndex, index);
        if (prev) {
          tokens.push({
            text: prev,
            isMatch: false
          });
        }
        tokens.push({
          text: match,
          isMatch: true
        });
        lastIndex = index + match.length;
      });

      // Append the missing text as a token.
      let last = string.slice(lastIndex);
      if (last) {
        tokens.push({
          text: last,
          isMatch: false
        });
      }
      return tokens;
    }
    get regex() {
      let flags = this.flags;
      let text = this.searchText;
      return this.getRegExp(text, flags);
    }
    getRegExp(term, flags) {
      let startFlag = '';
      let endFlag = '';
      let regexTerm = this.sanitizeRegex(term);
      if (flags.indexOf('^') >= 0) {
        startFlag = '^';
      }
      if (flags.indexOf('$') >= 0) {
        endFlag = '$';
      }
      return new RegExp(startFlag + regexTerm + endFlag, flags.replace(/[$^]/g, ''));
    }
    sanitizeRegex(term) {
      return term && term.toString().replace(/[\\^$*+?.()|{}[\]]/g, '\\$&');
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "tokens", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "tokens"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "regex", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "regex"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = _exports.default = PaperAutocompleteHighlight;
});