define("ember-paper/templates/components/paper-sidenav-inner", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "DYRHEWqU",
    "block": "[[[11,\"md-sidenav\"],[24,\"tabindex\",\"-1\"],[16,0,[29,[[30,0,[\"positionClass\"]],\" \",[52,[30,0,[\"isLockedOpen\"]],\"md-locked-open\"],\" \",[52,[30,0,[\"closed\"]],\"md-closed\"],\" \",[30,1]]]],[17,2],[4,[38,1],[\"click\",[28,[37,2],[[30,0,[\"handleClick\"]],[30,3],[30,4],[30,5]],null]],null],[12],[1,\"\\n  \"],[18,6,null],[1,\"\\n\"],[13]],[\"@class\",\"&attrs\",\"@closeOnClick\",\"@isLockedOpen\",\"@onToggle\",\"&default\"],false,[\"if\",\"on\",\"fn\",\"yield\"]]",
    "moduleName": "ember-paper/templates/components/paper-sidenav-inner.hbs",
    "isStrictMode": false
  });
});