define("ember-paper/components/paper-content/template", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "1xbNqm46",
    "block": "[[[11,\"md-content\"],[16,0,[29,[\"md-default-theme \",[52,[30,1],\"md-padding\"],\" \",[30,2]]]],[16,\"md-scroll-y\",[30,3]],[17,4],[12],[1,\"\\n  \"],[18,5,null],[1,\"\\n\"],[13]],[\"@padding\",\"@class\",\"@scroll-y\",\"&attrs\",\"&default\"],false,[\"if\",\"yield\"]]",
    "moduleName": "ember-paper/components/paper-content/template.hbs",
    "isStrictMode": false
  });
});