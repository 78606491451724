define("ember-paper/templates/components/paper-switch", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "NwLrMNR9",
    "block": "[[[10,0],[14,0,\"md-switch-bar\"],[12],[13],[1,\"\\n\"],[10,0],[14,0,\"md-container\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"md-bar\"],[12],[13],[1,\"\\n  \"],[10,0],[14,0,\"md-thumb-container\"],[15,5,[36,0]],[12],[1,\"\\n    \"],[10,0],[14,0,\"md-thumb\"],[12],[1,\"\\n      \"],[8,[39,1],null,[[\"@center\",\"@fitRipple\"],[true,true]],null],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,\"\\n\"],[41,[48,[30,1]],[[[1,\"  \"],[10,0],[14,0,\"md-label\"],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]],[[[1,\"  \"],[10,0],[14,0,\"md-label\"],[12],[1,\"\\n    \"],[1,[34,5]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[]]]],[\"&default\"],false,[\"thumbContainerStyle\",\"paper-ripple\",\"if\",\"has-block\",\"yield\",\"label\"]]",
    "moduleName": "ember-paper/templates/components/paper-switch.hbs",
    "isStrictMode": false
  });
});