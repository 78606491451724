define('ember-ace/components/ember-ace-completion-tooltip', ['exports', 'ember-ace/templates/components/ember-ace-completion-tooltip'], function (exports, _emberAceCompletionTooltip) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = Ember.Component.extend({
    layout: _emberAceCompletionTooltip.default,
    tagName: ''
  });
});