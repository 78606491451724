define("ember-paper/components/paper-autocomplete/no-matches-message/component", ["exports", "@ember/component", "ember-paper/components/paper-autocomplete/no-matches-message/template", "@ember-decorators/component"], function (_exports, _component, _template, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  let PaperAutocompleteNoMatchesMessage = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _component2.layout)(_template.default), _dec(_class = _dec2(_class = class PaperAutocompleteNoMatchesMessage extends _component.default {}) || _class) || _class);
  var _default = _exports.default = PaperAutocompleteNoMatchesMessage;
});