define("ember-paper/validators/required", ["exports", "@ember/utils"], function (_exports, _utils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.required = required;
  /**
   * @module ember-paper
   */

  function required(value, required) {
    return required === true && !(0, _utils.isEmpty)(value) || required !== true;
  }
  var _default = _exports.default = {
    param: 'required',
    message: 'This is required.',
    // required can be a boolean or 'style' for just required asterisk styling.
    validate: required
  };
});