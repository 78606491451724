define("ember-paper/components/paper-card-header-headline", ["exports", "@ember/component"], function (_exports, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */
  /**
   * @class PaperCardHeaderHeadline
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    tagName: 'span',
    classNames: ['md-headline']
  });
});