define("ember-paper/templates/components/paper-reset-button", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "mqSz0z9a",
    "block": "[[[11,\"button\"],[24,4,\"button\"],[17,1],[4,[38,0],[\"click\",[28,[37,1],[[30,0,[\"handleClick\"]],[30,2]],null]],null],[4,[38,2],[\"ng\"],[[\"didTransitionOut\"],[[30,3]]]],[12],[1,\"\\n  \"],[18,4,null],[1,\"\\n\"],[13]],[\"&attrs\",\"@onReset\",\"@onDidTransitionOut\",\"&default\"],false,[\"on\",\"fn\",\"css-transition\",\"yield\"]]",
    "moduleName": "ember-paper/templates/components/paper-reset-button.hbs",
    "isStrictMode": false
  });
});