define("ember-paper/components/paper-icon", ["exports", "@ember/component", "@ember/object", "@ember/object/computed", "@ember/string", "ember-paper/templates/components/paper-icon", "ember-paper/mixins/color-mixin"], function (_exports, _component, _object, _computed, _string, _paperIcon, _colorMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */

  /**
   * @class PaperIcon
   * @extends Ember.Component
   * @uses ColorMixin
   */
  let PaperIconComponent = _component.default.extend(_colorMixin.default, {
    layout: _paperIcon.default,
    tagName: 'md-icon',
    classNames: ['paper-icon', 'md-font', 'material-icons', 'md-default-theme'],
    classNameBindings: ['spinClass'],
    attributeBindings: ['aria-hidden', 'aria-label', 'title', 'sizeStyle:style', 'iconClass:md-font-icon'],
    icon: '',
    spin: false,
    reverseSpin: false,
    iconClass: (0, _object.computed)('icon', 'positionalIcon', function () {
      let icon = this.getWithDefault('positionalIcon', this.icon);
      return icon;
    }),
    'aria-hidden': false,
    'aria-label': (0, _computed.reads)('iconClass'),
    spinClass: (0, _object.computed)('spin', 'reverseSpin', function () {
      if (this.spin) {
        return 'md-spin';
      } else if (this.reverseSpin) {
        return 'md-spin-reverse';
      } else {
        return null;
      }
    }),
    sizeStyle: (0, _object.computed)('size', function () {
      let size = this.size;
      if (size) {
        return (0, _string.htmlSafe)(`height: ${size}px; min-height: ${size}px; min-width: ${size}px; font-size: ${size}px; line-height: ${size}px;`);
      } else {
        return null;
      }
    })
  });
  PaperIconComponent.reopenClass({
    positionalParams: ['positionalIcon']
  });
  var _default = _exports.default = PaperIconComponent;
});