define("ember-gridstack/components/grid-stack-item", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/service", "@ember/object", "@ember/object/internals"], function (_exports, _component, _templateFactory, _component2, _tracking, _service, _object, _internals) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _class, _descriptor, _descriptor2, _descriptor3;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! Copyright 2021, Yahoo Inc }}
  <div
    id={{this.guid}}
    class="grid-stack-item"
    ...attributes
    {{gs-item-attributes @options}}
    {{did-insert this.setup}}
    {{did-update this.updateGridStack @options}}
    {{will-destroy this.willDestroyNode}}
  >
    <div class="grid-stack-item-content">
      {{yield this}}
    </div>
  </div>
  */
  {
    "id": "MLnEcKjB",
    "block": "[[[11,0],[16,1,[30,0,[\"guid\"]]],[24,0,\"grid-stack-item\"],[17,1],[4,[38,0],[[30,2]],null],[4,[38,1],[[30,0,[\"setup\"]]],null],[4,[38,2],[[30,0,[\"updateGridStack\"]],[30,2]],null],[4,[38,3],[[30,0,[\"willDestroyNode\"]]],null],[12],[1,\"\\n  \"],[10,0],[14,0,\"grid-stack-item-content\"],[12],[1,\"\\n    \"],[18,3,[[30,0]]],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&attrs\",\"@options\",\"&default\"],false,[\"gs-item-attributes\",\"did-insert\",\"did-update\",\"will-destroy\",\"yield\"]]",
    "moduleName": "ember-gridstack/components/grid-stack-item.hbs",
    "isStrictMode": false
  });
  /**
   * Copyright 2021, Yahoo Inc.
   * Copyrights licensed under the BSD License. See the accompanying LICENSE file for terms.
   *
   * Usage:
   *   <GridStackItem @options={{hash minW=2 maxW=4}} as |item|>
   *     My widget content
   *   </GridStackItem>
   *
   * Full list of options:
   *   https://github.com/gridstack/gridstack.js/tree/master/doc#item-options
   */
  let GridStackItem = _exports.default = (_class = class GridStackItem extends _component2.default {
    constructor(...args) {
      super(...args);
      _initializerDefineProperty(this, "gridStackRegistry", _descriptor, this);
      _defineProperty(this, "guid", (0, _internals.guidFor)(this));
      _initializerDefineProperty(this, "elm", _descriptor2, this);
      _initializerDefineProperty(this, "gridStackComponent", _descriptor3, this);
    }
    get element() {
      return document.getElementById(this.guid);
    }
    setup(elm) {
      this.elm = elm;
      this.gridStackComponent = this.gridStackRegistry.findGridComponent(this.elm);
      this.gridStackComponent?.addWidget(this.elm);
    }
    updateGridStack() {
      this.gridStackComponent?.gridStack?.update(this.elm, {
        ...this.args.options
      });
    }
    willDestroyNode() {
      this.gridStackComponent?.removeWidget(this.elm);
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "gridStackRegistry", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "elm", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor3 = _applyDecoratedDescriptor(_class.prototype, "gridStackComponent", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "updateGridStack", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "updateGridStack"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroyNode", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyNode"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GridStackItem);
});