define("ember-paper/templates/components/paper-option", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "YZEhjGus",
    "block": "[[[10,0],[14,0,\"md-text\"],[12],[18,1,null],[13]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-paper/templates/components/paper-option.hbs",
    "isStrictMode": false
  });
});