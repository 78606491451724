define("ember-paper/templates/components/paper-card", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "rl3HgQxM",
    "block": "[[[18,1,[[28,[37,1],null,[[\"title\",\"content\",\"actions\",\"header\",\"image\",\"media\"],[[50,\"paper-card-title\",0,null,null],[50,\"paper-card-content\",0,null,null],[50,\"paper-card-actions\",0,null,null],[50,\"paper-card-header\",0,null,null],[50,\"paper-card-image\",0,null,null],[50,\"paper-card-media\",0,null,null]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\"]]",
    "moduleName": "ember-paper/templates/components/paper-card.hbs",
    "isStrictMode": false
  });
});