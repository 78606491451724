define("ember-paper/components/paper-slider/component", ["exports", "@ember/component", "@ember/object", "@ember/runloop", "@ember/string", "ember-paper/components/paper-slider/template", "ember-paper/utils/clamp", "@ember-decorators/component"], function (_exports, _component, _object, _runloop, _string, _template, _clamp, _component2) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _dec5, _dec6, _class, _class2;
  /**
   * @module ember-paper
   */
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  /* global Hammer */
  /**
   * @class PaperSlider
   * @extends Ember.Component
   */
  let PaperSlider = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _component2.layout)(_template.default), _dec3 = (0, _object.computed)('value', 'min', 'max'), _dec4 = (0, _object.computed)('percent'), _dec5 = (0, _object.computed)('percent'), _dec6 = (0, _object.computed)('percent', 'min'), _dec(_class = _dec2(_class = (_class2 = class PaperSlider extends _component.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "min", 0);
      _defineProperty(this, "max", 100);
      _defineProperty(this, "step", 1);
      _defineProperty(this, "tabindex", 0);
      _defineProperty(this, "active", false);
      _defineProperty(this, "dragging", false);
      _defineProperty(this, "focused", false);
      _defineProperty(this, "element", null);
    }
    get percent() {
      let min = parseFloat(this.min, 10);
      let max = parseFloat(this.max, 10);
      return (0, _clamp.default)((this.value - min) / (max - min), 0, 1);
    }
    get activeTrackStyle() {
      let percent = this.percent || 0;
      return (0, _string.htmlSafe)(`width: ${percent * 100}%`);
    }
    get thumbContainerStyle() {
      let percent = this.percent || 0;
      return (0, _string.htmlSafe)(`left: ${percent * 100}%`);
    }
    get isMinimum() {
      return this.percent === this.min;
    }
    onDidInsert(element) {
      this.element = element;
      if (!this.disabled) {
        this._setupHammer();
      }
    }
    onDidUpdate() {
      if (!this.disabled && !this._hammer) {
        // if it is enabled and we didn't init hammer yet
        this._setupHammer();
      } else if (this.disabled && this._hammer) {
        // if it is disabled and we did init hammer already
        this._teardownHammer();
      }
    }
    onWillDestroy() {
      if (this._hammer) {
        this._teardownHammer();
      }
    }
    _setupHammer() {
      // Enable dragging the slider
      let containerManager = new Hammer.Manager(this.element);
      let pan = new Hammer.Pan({
        direction: Hammer.DIRECTION_HORIZONTAL,
        threshold: 10
      });
      containerManager.add(pan);
      let tap = new Hammer.Tap();
      containerManager.add(tap);
      containerManager.on('panstart', (0, _runloop.bind)(this, this.onDragStart)).on('panmove', (0, _runloop.bind)(this, this.onDrag)).on('panend', (0, _runloop.bind)(this, this.onDragEnd)).on('tap', (0, _runloop.bind)(this, this.onTap));
      this._hammer = containerManager;
    }
    _teardownHammer() {
      this._hammer.destroy();
      delete this._hammer;
    }
    positionToPercent(x) {
      let {
        left,
        width
      } = this.sliderDimensions();
      return Math.max(0, Math.min(1, (x - left) / width));
    }
    percentToValue(x) {
      let min = parseFloat(this.min, 10);
      let max = parseFloat(this.max, 10);
      return min + x * (max - min);
    }
    minMaxValidator(value) {
      let min = parseFloat(this.min, 10);
      let max = parseFloat(this.max, 10);
      return Math.max(min, Math.min(max, value));
    }
    stepValidator(value) {
      let step = parseFloat(this.step, 10);
      return Math.round(value / step) * step;
    }
    sliderDimensions() {
      return this.element.querySelector('.md-track-container').getBoundingClientRect();
    }
    setValueFromEvent(value) {
      let exactVal = this.percentToValue(this.positionToPercent(value));
      let closestVal = this.minMaxValidator(this.stepValidator(exactVal));
      if (this.onChange) {
        this.onChange(closestVal);
      }
    }
    onTap(event) {
      if (this.disabled) {
        return;
      }
      this.setValueFromEvent(event.center.x);
    }
    onDragStart(event) {
      if (this.disabled) {
        return;
      }
      this.set('active', true);
      this.set('dragging', true);
      this.element.focus();
      this.setValueFromEvent(event.center.x);
    }
    onDrag(event) {
      if (this.disabled || !this.dragging) {
        return;
      }
      this.setValueFromEvent(event.center.x);
    }
    onDragEnd() {
      if (this.disabled) {
        return;
      }
      this.set('active', false);
      this.set('dragging', false);
    }
    handleKeyDown(event) {
      if (this.disabled) {
        return;
      }
      let changeAmount, newValue;
      if (['ArrowLeft', 'Left'].includes(event.key)) {
        changeAmount = parseFloat(this.step) * -1;
      } else if (['ArrowRight', 'Right'].includes(event.key)) {
        changeAmount = parseFloat(this.step);
      }
      if (changeAmount) {
        if (event.metaKey || event.ctrlKey || event.altKey) {
          changeAmount *= 4;
        }
        newValue = this.value + changeAmount;
        if (this.onChange) {
          this.onChange(this.minMaxValidator(newValue));
        }
        event.preventDefault();
        event.stopPropagation();
      }
    }
    handleFocusIn() {
      if (!this.disabled) {
        this.set('focused', true);
      }
    }
    handleFocusOut() {
      this.set('focused', false);
    }
  }, (_applyDecoratedDescriptor(_class2.prototype, "percent", [_dec3], Object.getOwnPropertyDescriptor(_class2.prototype, "percent"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "activeTrackStyle", [_dec4], Object.getOwnPropertyDescriptor(_class2.prototype, "activeTrackStyle"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "thumbContainerStyle", [_dec5], Object.getOwnPropertyDescriptor(_class2.prototype, "thumbContainerStyle"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "isMinimum", [_dec6], Object.getOwnPropertyDescriptor(_class2.prototype, "isMinimum"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onDidInsert", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onDidInsert"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onDidUpdate", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onDidUpdate"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "onWillDestroy", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "onWillDestroy"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleKeyDown", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleKeyDown"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleFocusIn", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleFocusIn"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "handleFocusOut", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "handleFocusOut"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = _exports.default = PaperSlider;
});