define("ember-css-transitions/modifiers/css-transition", ["exports", "ember-modifier", "ember-css-transitions/utils/transition-utils"], function (_exports, _emberModifier, _transitionUtils) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /**
    Modifier that applies classes. Usage:
  
    ```hbs
    <div {{css-transition name="example"}}>
      <p>Hello world</p>
    </div>
    ```
  
    @class CssTransitionModifier
    @argument {Function} [didTransitionIn]
    @argument {Function} [didTransitionOut]
    @public
  */
  class CssTransitionModifier extends _emberModifier.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "clone", null);
      _defineProperty(this, "parentElement", null);
      _defineProperty(this, "nextElementSibling", null);
      _defineProperty(this, "installed", false);
    }
    /**
     * @type {(HTMLElement|undefined)}
     * @private
     * @readonly
     */
    get el() {
      return this.clone || this.element;
    }

    /**
     * @type {(String|undefined)}
     * @private
     * @readonly
     */
    get transitionName() {
      return this.args.positional[0] || this.args.named.name;
    }

    /**
     * @type {(String|undefined)}
     * @private
     * @readonly
     */
    get enterClass() {
      return this.args.named.enterClass || this.transitionName && `${this.transitionName}-enter`;
    }

    /**
     * @type {(String|undefined)}
     * @private
     * @readonly
     */
    get enterActiveClass() {
      return this.args.named.enterActiveClass || this.transitionName && `${this.transitionName}-enter-active`;
    }

    /**
     * @type {(String|undefined)}
     * @private
     * @readonly
     */
    get enterToClass() {
      return this.args.named.enterToClass || this.transitionName && `${this.transitionName}-enter-to`;
    }

    /**
     * @type {(String|undefined)}
     * @private
     * @readonly
     */
    get leaveClass() {
      return this.args.named.leaveClass || this.transitionName && `${this.transitionName}-leave`;
    }

    /**
     * @type {(String|undefined)}
     * @private
     * @readonly
     */
    get leaveActiveClass() {
      return this.args.named.leaveActiveClass || this.transitionName && `${this.transitionName}-leave-active`;
    }

    /**
     * @type {(String|undefined)}
     * @private
     * @readonly
     */
    get leaveToClass() {
      return this.args.named.leaveToClass || this.transitionName && `${this.transitionName}-leave-to`;
    }
    didInstall() {
      if (this.args.named.isEnabled === false) {
        return;
      }
      this.parentElement = this.element.parentElement;
      this.nextElementSibling = this.element.nextElementSibling;
      this.guardedRun(this.transitionIn);
    }
    willRemove() {
      if (this.args.named.isEnabled === false || !this.installed) {
        return;
      }
      this.guardedRun(this.transitionOut);
    }

    /**
     * Adds a clone to the parentElement so it can be transitioned out
     *
     * @private
     * @method addClone
     */
    addClone() {
      let original = this.element;
      let parentElement = original.parentElement || this.parentElement;
      let nextElementSibling = original.nextElementSibling || this.nextElementSibling;
      if (nextElementSibling && nextElementSibling.parentElement !== parentElement) {
        nextElementSibling = null;
      }
      let clone = original.cloneNode(true);
      clone.setAttribute('id', `${original.id}_clone`);
      parentElement.insertBefore(clone, nextElementSibling);
      this.clone = clone;
    }

    /**
     * Removes the clone from the parentElement
     *
     * @private
     * @method removeClone
     */
    removeClone() {
      if (this.clone.isConnected && this.clone.parentNode !== null) {
        this.clone.parentNode.removeChild(this.clone);
      }
    }
    *transitionIn() {
      if (this.enterClass) {
        yield* this.transition({
          className: this.enterClass,
          activeClassName: this.enterActiveClass,
          toClassName: this.enterToClass
        });
        if (this.args.named.didTransitionIn) {
          this.args.named.didTransitionIn();
        }
      }
      this.installed = true;
    }
    *transitionOut() {
      if (this.leaveClass) {
        // We can't stop ember from removing the element
        // so we clone the element to animate it out
        this.addClone();
        yield (0, _transitionUtils.nextTick)();
        yield* this.transition({
          className: this.leaveClass,
          activeClassName: this.leaveActiveClass,
          toClassName: this.leaveToClass
        });
        this.removeClone();
        if (this.args.named.didTransitionOut) {
          this.args.named.didTransitionOut();
        }
        this.clone = null;
      }
    }

    /**
     * Transitions the element.
     *
     * @private
     * @method transition
     * @param {Object} args
     * @param {String} args.className the class representing the starting state
     * @param {String} args.activeClassName the class applied during the entire transition. This class can be used to define the duration, delay and easing curve.
     * @param {String} args.toClassName the class representing the finished state
     * @return {Generator}
     */
    *transition({
      className,
      activeClassName,
      toClassName
    }) {
      let element = this.el;

      // add first class right away
      this.addClass(className);
      this.addClass(activeClassName);
      yield (0, _transitionUtils.nextTick)();

      // This is for to force a repaint,
      // which is necessary in order to transition styles when adding a class name.
      element.scrollTop;

      // after repaint
      this.addClass(toClassName);
      this.removeClass(className);

      // wait for ember to apply classes
      // set timeout for animation end
      yield (0, _transitionUtils.sleep)((0, _transitionUtils.computeTimeout)(element) || 0);
      this.removeClass(toClassName);
      this.removeClass(activeClassName);
    }

    /**
     * Add classNames to el.
     *
     * @private
     * @method addClass
     * @param {String} classNames
     */
    addClass(className) {
      this.el.classList.add(...className.trim().split(/\s+/));
    }

    /**
     * Remove classNames from el.
     *
     * @private
     * @method removeClass
     * @param {String} classNames
     */
    removeClass(className) {
      this.el.classList.remove(...className.trim().split(/\s+/));
    }
    async guardedRun(f, ...args) {
      let gen = f.call(this, ...args);
      let isDone = false;

      // stop if the function doesn't have anything else to yield
      // or if the element is no longer present
      while (!isDone && this.el) {
        let {
          value,
          done
        } = gen.next();
        isDone = done;
        await value;
      }
    }
  }
  _exports.default = CssTransitionModifier;
});