define("ember-paper/utils/invoke-action", ["exports", "@ember/debug", "@ember/object"], function (_exports, _debug, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.invokeAction = invokeAction;
  function invokeAction(object, actionName, ...args) {
    (false && !(typeof object === 'object') && (0, _debug.assert)('The first argument passed to invokeAction must be an object', typeof object === 'object'));
    let action;
    if (typeof actionName === 'string') {
      action = (0, _object.get)(object, actionName);
    } else if (typeof actionName === 'function') {
      action = actionName;
    } else {
      (false && !(false) && (0, _debug.assert)('The second argument passed to invokeAction must be a string as actionName or a function', false));
    }
    if (typeof action === 'string') {
      object.sendAction(actionName, ...args);
    } else if (typeof action === 'function') {
      return action(...args);
    }
  }
  ;
});