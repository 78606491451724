define("ember-paper/templates/components/paper-optgroup", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "qCIcRuRX",
    "block": "[[[10,\"label\"],[12],[1,[34,0]],[13],[1,\"\\n\"],[18,1,null]],[\"&default\"],false,[\"label\",\"yield\"]]",
    "moduleName": "ember-paper/templates/components/paper-optgroup.hbs",
    "isStrictMode": false
  });
});