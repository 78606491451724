define("ember-paper/components/paper-dialog", ["exports", "@ember/object/computed", "@ember/component", "@ember/object", "@ember/application", "ember-paper/templates/components/paper-dialog", "ember-paper/utils/invoke-action"], function (_exports, _computed, _component, _object, _application, _paperDialog, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */
  /**
   * @class PaperDialog
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    layout: _paperDialog.default,
    tagName: '',
    escapeToClose: true,
    focusOnOpen: true,
    opaque: true,
    // Calculate a default that is always valid for the parent of the backdrop.
    wormholeSelector: '#paper-wormhole',
    defaultedParent: (0, _computed.or)('parent', 'wormholeSelector'),
    // Calculate a default that is always valid where the opening transition should originate.
    defaultedOpenFrom: (0, _computed.or)('openFrom', 'origin', 'parent'),
    // Calculate a default that is always valid where the closing transition should terminate.
    defaultedCloseTo: (0, _computed.or)('closeTo', 'origin', 'parent'),
    // Calculate the id of the wormhole destination, setting it if need be. The
    // id is that of the 'parent', if provided, or 'paper-wormhole' if not.
    destinationId: (0, _object.computed)('defaultedParent', function () {
      let config = (0, _application.getOwner)(this).resolveRegistration('config:environment');
      if (config.environment === 'test' && !this.parent) {
        return '#ember-testing';
      }
      let parent = this.defaultedParent;
      let parentEle = typeof parent === 'string' ? document.querySelector(parent) : parent;
      // If the parentEle isn't found, assume that it is an id, but that the DOM doesn't
      // exist yet. This only happens during integration tests or if entire application
      // route is a dialog.
      if (typeof parent === 'string' && parent.charAt(0) === '#') {
        return `#${parent.substring(1)}`;
      } else {
        let id = parentEle.getAttribute('id');
        if (!id) {
          id = `${this.elementId}-parent`;
          parentEle.setAttribute('id', id);
        }
        return `#${id}`;
      }
    }),
    // Find the element referenced by destinationId
    destinationEl: (0, _object.computed)('destinationId', function () {
      return document.querySelector(this.destinationId);
    }),
    didInsertElement() {
      this._super(...arguments);
      if (this.escapeToClose) {
        this._destinationEle = document.querySelector(this.destinationId);
        this._onKeyDown = e => {
          if (e.keyCode === 27 && this.onClose) {
            (0, _invokeAction.invokeAction)(this, 'onClose');
          }
        };
        this._destinationEle.addEventListener('keydown', this._onKeyDown);
      }
    },
    willDestroyElement() {
      this._super(...arguments);
      if (this.escapeToClose && this._destinationEle) {
        this._destinationEle.removeEventListener('keydown', this._onKeyDown);
        this._onKeyDown = null;
      }
    },
    actions: {
      outsideClicked() {
        if (this.clickOutsideToClose && this.onClose) {
          (0, _invokeAction.invokeAction)(this, 'onClose');
        }
      }
    }
  });
});