define("ember-paper/templates/components/paper-radio-group-label", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Jz5iPJ42",
    "block": "[[[41,[48,[30,1]],[[[1,\"  \"],[18,1,null],[1,\"\\n\"]],[]],[[[1,\"  \"],[1,[34,3]],[1,\"\\n\"]],[]]],[1,\"\\n\"]],[\"&default\"],false,[\"if\",\"has-block\",\"yield\",\"text\"]]",
    "moduleName": "ember-paper/templates/components/paper-radio-group-label.hbs",
    "isStrictMode": false
  });
});