define("ember-paper/components/paper-divider/component", ["exports", "@ember/component", "@ember-decorators/component", "ember-paper/components/paper-divider/template"], function (_exports, _component, _component2, _template) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _class;
  /**
   * @module ember-paper
   */
  /**
   * @class PaperDivider
   * @extends Component
   */
  let PaperDivider = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _component2.layout)(_template.default), _dec(_class = _dec2(_class = class PaperDivider extends _component.default {}) || _class) || _class);
  var _default = _exports.default = PaperDivider;
});