define("ember-paper/helpers/underscore", ["exports", "@ember/component/helper", "@ember/string"], function (_exports, _helper, _string) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  _exports.underscore = underscore;
  /**
   * @module ember-paper
   */

  function underscore([text]) {
    return (0, _string.underscore)(text);
  }
  var _default = _exports.default = _helper.default.helper(underscore);
});