define("ember-paper/templates/components/paper-progress-circular", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "LZZxGdP4",
    "block": "[[[10,\"svg\"],[14,\"xmlns\",\"http://www.w3.org/2000/svg\",\"http://www.w3.org/2000/xmlns/\"],[15,\"viewBox\",[29,[\"0 0 \",[36,0],\" \",[36,0]]]],[15,5,[36,1]],[12],[1,\"\\n  \"],[10,\"path\"],[14,\"fill\",\"none\"],[15,5,[36,2]],[15,\"stroke-dasharray\",[36,3]],[15,\"d\",[36,4]],[12],[13],[1,\"\\n\"],[13]],[],false,[\"diameter\",\"svgStyle\",\"pathStyle\",\"strokeDasharray\",\"d\"]]",
    "moduleName": "ember-paper/templates/components/paper-progress-circular.hbs",
    "isStrictMode": false
  });
});