define("ember-paper/mixins/color-mixin", ["exports", "@ember/object/mixin"], function (_exports, _mixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */
  /**
   * @class ColorMixin
   * @extends Ember.Mixin;
   */
  var _default = _exports.default = _mixin.default.create({
    classNameBindings: ['warn:md-warn', 'accent:md-accent', 'primary:md-primary']
  });
});