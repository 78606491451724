define("ember-model-validator/decorators/object-validator", ["exports", "ember-data", "@ember/object", "ember-model-validator/decorators/core-validator"], function (_exports, _emberData, _object, _coreValidator) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function objectValidator(Class) {
    var _class;
    let ObjectValidator = (0, _coreValidator.default)(_class = class ObjectValidator extends Class {
      constructor(...args) {
        super(...args);
        _defineProperty(this, "errors", _emberData.default.Errors.create());
      }
      clearErrors() {
        (0, _object.set)(this, 'errors', _emberData.default.Errors.create());
        (0, _object.set)(this, 'validationErrors', {});
        (0, _object.set)(this, 'isValidNow', true);
      }
      pushErrors(errors) {
        for (let attribute in errors) {
          let messages = errors[attribute];
          this.errors.add(attribute, messages);
        }
      }
      _modelRelations() {}
    }) || _class;
    return ObjectValidator;
  }
  var _default = _exports.default = objectValidator;
});