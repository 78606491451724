define("ember-paper/components/paper-autocomplete/options/index", ["exports", "@ember/component", "@ember/template-factory", "ember-power-select/components/power-select/options"], function (_exports, _component, _templateFactory, _options) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! template-lint-disable no-unnecessary-concat }}
  <VerticalCollection
    @items={{@options}}
    @tagName="ul"
    class="md-autocomplete-suggestions"
    @containerSelector=".md-autocomplete-suggestions-container"
    @estimateHeight={{48}}
    @staticHeight={{true}}
    @bufferSize={{3}}
    role="listbox"
    aria-controls="ember-power-select-trigger-{{@select.uniqueId}}"
    {{did-insert this.addHandlers}}
    ...attributes
    as |opt index|>
    <li class="ember-power-select-option md-autocomplete-suggestion {{if (eq opt @select.highlighted) "selected"}}"
      aria-selected="{{ember-power-select-is-selected opt @select.selected}}"
      aria-disabled={{if opt.disabled "true"}}
      aria-current="{{eq opt @select.highlighted}}"
      data-option-index="{{@groupIndex}}{{index}}"
      role="option"
      tabindex={{if opt.disabled "-1" "0"}}>
      {{yield opt @select}}
    </li>
  </VerticalCollection>
  */
  {
    "id": "vMkcSk6Z",
    "block": "[[[8,[39,0],[[24,0,\"md-autocomplete-suggestions\"],[24,\"role\",\"listbox\"],[16,\"aria-controls\",[29,[\"ember-power-select-trigger-\",[30,1,[\"uniqueId\"]]]]],[17,2],[4,[38,1],[[30,0,[\"addHandlers\"]]],null]],[[\"@items\",\"@tagName\",\"@containerSelector\",\"@estimateHeight\",\"@staticHeight\",\"@bufferSize\"],[[30,3],\"ul\",\".md-autocomplete-suggestions-container\",48,true,3]],[[\"default\"],[[[[1,\"\\n  \"],[10,\"li\"],[15,0,[29,[\"ember-power-select-option md-autocomplete-suggestion \",[52,[28,[37,3],[[30,4],[30,1,[\"highlighted\"]]],null],\"selected\"]]]],[15,\"aria-selected\",[29,[[28,[37,4],[[30,4],[30,1,[\"selected\"]]],null]]]],[15,\"aria-disabled\",[52,[30,4,[\"disabled\"]],\"true\"]],[15,\"aria-current\",[29,[[28,[37,3],[[30,4],[30,1,[\"highlighted\"]]],null]]]],[15,\"data-option-index\",[29,[[30,6],[30,5]]]],[14,\"role\",\"option\"],[15,\"tabindex\",[52,[30,4,[\"disabled\"]],\"-1\",\"0\"]],[12],[1,\"\\n    \"],[18,7,[[30,4],[30,1]]],[1,\"\\n  \"],[13],[1,\"\\n\"]],[4,5]]]]]],[\"@select\",\"&attrs\",\"@options\",\"opt\",\"index\",\"@groupIndex\",\"&default\"],false,[\"vertical-collection\",\"did-insert\",\"if\",\"eq\",\"ember-power-select-is-selected\",\"yield\"]]",
    "moduleName": "ember-paper/components/paper-autocomplete/options/index.hbs",
    "isStrictMode": false
  });
  class PaperAutocompleteOptions extends _options.default {}
  _exports.default = PaperAutocompleteOptions;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, PaperAutocompleteOptions);
});