define("ember-gridstack/components/grid-stack", ["exports", "@ember/component", "@ember/template-factory", "@glimmer/component", "@glimmer/tracking", "@ember/object", "@ember/service", "@ember/runloop", "@ember/string", "@ember/object/internals", "gridstack", "gridstack-h5"], function (_exports, _component, _templateFactory, _component2, _tracking, _object, _service, _runloop, _string, _internals, _gridstack, _gridstackH) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.GRID_STACK_EVENTS = void 0;
  var _class, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    {{! Copyright 2021, Yahoo Inc }}
  <div
    id={{this.guid}}
    class="grid-stack"
    {{did-insert this.setup}}
    {{did-update this.update @options}}
    {{will-destroy this.willDestroyNode}}
    ...attributes
  >
    {{yield this}}
  </div>
  */
  {
    "id": "fhc6P5+1",
    "block": "[[[11,0],[16,1,[30,0,[\"guid\"]]],[24,0,\"grid-stack\"],[17,1],[4,[38,0],[[30,0,[\"setup\"]]],null],[4,[38,1],[[30,0,[\"update\"]],[30,2]],null],[4,[38,2],[[30,0,[\"willDestroyNode\"]]],null],[12],[1,\"\\n  \"],[18,3,[[30,0]]],[1,\"\\n\"],[13]],[\"&attrs\",\"@options\",\"&default\"],false,[\"did-insert\",\"did-update\",\"will-destroy\",\"yield\"]]",
    "moduleName": "ember-gridstack/components/grid-stack.hbs",
    "isStrictMode": false
  });
  /**
   * Copyright 2021, Yahoo Inc.
   * Copyrights licensed under the BSD License. See the accompanying LICENSE file for terms.
   *
   * Usage:
   *   <GridStack
   *      @options={{hash animate=true}}
   *      @onDragstart={{this.dragStart}}
   *      @onDragstop={{this.dragStop}}
   *      @onResizestart={{this.resizeStart}}
   *      @onResizestop={{this.resizeStop}}
   *      @onAdded={{this.added}}
   *      @onChange={{this.change}}
   *      @onEnable={{this.enable}}
   *      @onRemoved={{this.remove}}
   *      as |grid|
   *   />
   *
   * Full list of options:
   *   https://github.com/gridstack/gridstack.js/tree/master/doc#grid-options
   */

  const GRID_STACK_EVENTS = _exports.GRID_STACK_EVENTS = ['added', 'change', 'disable', 'dragstart', 'drag', 'dragstop', 'dropped', 'enable', 'removed', 'resizestart', 'resize', 'resizestop'];
  let GridStackComponent = _exports.default = (_class = class GridStackComponent extends _component2.default {
    constructor() {
      super(...arguments);
      _initializerDefineProperty(this, "gridStackRegistry", _descriptor, this);
      _defineProperty(this, "guid", (0, _internals.guidFor)(this));
      _initializerDefineProperty(this, "elm", _descriptor2, this);
      /**
       * https://github.com/gridstack/gridstack.js/tree/master/doc#api
       * @property {GridStack|null} gridStack - reference to gridstack object
       */
      _defineProperty(this, "gridStack", null);
      /**
       * @property {Array} subscribedEvents - List of events for which event handlers were set up
       */
      _defineProperty(this, "subscribedEvents", []);
      this.gridStackRegistry.registerGrid(this.guid, this);
    }
    get options() {
      return {
        ...this.args.options
      };
    }
    _destroyGridStack() {
      const {
        gridStack
      } = this;
      if (gridStack) {
        this.subscribedEvents.forEach(eventName => gridStack.off(eventName));
        this.subscribedEvents = [];

        // Use `false` option to prevent removing dom elements, let Ember do that
        gridStack.destroy(false);
        this.gridStack = null;

        // Remove 'grid-stack-instance-####' class left behind
        [...this.elm.classList].filter(x => /grid-stack-instance-\d*/.test(x)).forEach(x => this.elm.classList.remove(x));
      }
    }
    _createGridStack() {
      this.gridStack = _gridstackH.default.init({
        ...this.options
      }, this.elm);
      GRID_STACK_EVENTS.forEach(eventName => {
        const action = this.args[`on${(0, _string.capitalize)(eventName)}`];
        if (action) {
          this.gridStack.on(eventName, function () {
            (0, _runloop.scheduleOnce)('afterRender', this, action, ...arguments);
          });
          this.subscribedEvents.push(eventName);
        }
      });
    }
    setup(elm) {
      this.elm = elm;
      this._createGridStack();
    }
    update() {
      this._destroyGridStack();
      this._createGridStack();
    }
    willDestroyNode() {
      this.gridStackRegistry.unregisterGridComponent(this.guid, this);
      this._destroyGridStack();
    }
    addWidget(element) {
      this.gridStack?.makeWidget(element);
    }

    /**
     * Custom removeWidget function that skips check to see if widget is in current grid
     * @see https://github.com/gridstack/gridstack.js/blob/v4.2.5/src/gridstack.ts#L893
     */
    removeWidget(element, removeDOM = false, triggerEvent = true) {
      _gridstackH.default.getElements(element).forEach(el => {
        // The following line was causing issues because this hook is called correctly from
        // child widgets, but after they are already removed from the dom
        // --- SKIP ---
        // if (el.parentElement !== this.el) return; // not our child!
        // --- SKIP ---
        let node = el.gridstackNode;
        // For Meteor support: https://github.com/gridstack/gridstack.js/pull/272
        if (!node) {
          node = this.gridStack?.engine.nodes.find(n => el === n.el);
        }
        if (!node) return;

        // remove our DOM data (circular link) and drag&drop permanently
        delete el.gridstackNode;
        _gridstack.GridStackDDI.get().remove(el);
        this.gridStack?.engine.removeNode(node, removeDOM, triggerEvent);
        if (removeDOM && el.parentElement) {
          el.remove(); // in batch mode engine.removeNode doesn't call back to remove DOM
        }
      });
      if (triggerEvent && !this.isDestroying && !this.isDestroyed) {
        this.gridStack?._triggerRemoveEvent();
        this.gridStack?._triggerChangeEvent();
      }
      return this;
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class.prototype, "gridStackRegistry", [_service.inject], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class.prototype, "elm", [_tracking.tracked], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class.prototype, "setup", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "setup"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "update", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "update"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "willDestroyNode", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "willDestroyNode"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "addWidget", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "addWidget"), _class.prototype), _applyDecoratedDescriptor(_class.prototype, "removeWidget", [_object.action], Object.getOwnPropertyDescriptor(_class.prototype, "removeWidget"), _class.prototype)), _class);
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, GridStackComponent);
});