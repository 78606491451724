define("ember-paper/modifiers/toast-hammer", ["exports", "ember-modifier", "@ember/runloop"], function (_exports, _emberModifier, _runloop) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  /* global Hammer */

  class ToastHammer extends _emberModifier.default {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "enabled", false);
      _defineProperty(this, "onClose", null);
    }
    setupHammer() {
      if (this.enabled) {
        // Enable dragging the slider
        let containerManager = new Hammer.Manager(this.element, {
          dragLockToAxis: true,
          dragBlockHorizontal: true
        });
        let swipe = new Hammer.Swipe({
          direction: Hammer.DIRECTION_ALL,
          threshold: 10
        });
        let pan = new Hammer.Pan({
          direction: Hammer.DIRECTION_ALL,
          threshold: 10
        });
        containerManager.add(swipe);
        containerManager.add(pan);
        containerManager.on('panstart', _runloop.run.bind(this, this.dragStart)).on('panmove', _runloop.run.bind(this, this.drag)).on('panend', _runloop.run.bind(this, this.dragEnd)).on('swiperight swipeleft', _runloop.run.bind(this, this.dragEnd));
        this.hammer = containerManager;
      }
    }
    setXPosition(event) {
      this.element.style.transform = `translate(${event}px, 0)`;
    }
    dragStart(event) {
      this.element.classList.add('md-dragging');
      this.element.focus();
      this.setXPosition(event.center.x);
    }
    drag(event) {
      if (!this.element.classList.contains('md-dragging')) {
        return;
      }
      this.setXPosition(event.deltaX);
    }
    dragEnd() {
      this.element.classList.remove('md-dragging');
      if (this.onClose) {
        this.onClose();
      }
    }
    teardownHammer() {
      this.hammer.destroy();
      delete this.hammer;
    }
    didReceiveArguments() {
      this.enabled = this.args.named.enabled;
      this.onClose = this.args.named.onClose;
      if (this.enabled && !this.hammer) {
        // if it is enabled and we didn't init hammer yet
        this.setupHammer();
      } else if (!this.enabled && this.hammer) {
        // if it is disabled and we did init hammer already
        this.teardownHammer();
      }
    }
    willDestroy() {
      if (this.hammer) {
        this.teardownHammer();
      }
    }
  }
  _exports.default = ToastHammer;
});