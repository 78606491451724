define("ember-paper/utils/browser-features", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.supportsPassiveEventListeners = void 0;
  // Taken from https://github.com/Modernizr/Modernizr/blob/master/feature-detects/dom/passiveeventlisteners.js
  /* eslint-disable */
  const supportsPassiveEventListeners = _exports.supportsPassiveEventListeners = function () {
    var supportsPassiveOption = false;
    try {
      var opts = Object.defineProperty({}, 'passive', {
        get: function () {
          supportsPassiveOption = true;
        }
      });
      var noop = function () {};
      window.addEventListener('testPassiveEventSupport', noop, opts);
      window.removeEventListener('testPassiveEventSupport', noop, opts);
    } catch (e) {}
    return supportsPassiveOption;
  }();
});