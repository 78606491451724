define("ember-paper/components/paper-button", ["exports", "@ember/object/computed", "@ember/component", "ember-paper/templates/components/paper-button", "ember-paper/mixins/focusable-mixin", "ember-paper/mixins/color-mixin", "ember-paper/mixins/proxiable-mixin", "ember-paper/utils/invoke-action"], function (_exports, _computed, _component, _paperButton, _focusableMixin, _colorMixin, _proxiableMixin, _invokeAction) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */
  /**
   * @class PaperButton
   * @extends Ember.Component
   * @uses FocusableMixin
   * @uses ColorMixin
   * @uses ProxiableMixin
   */
  var _default = _exports.default = _component.default.extend(_focusableMixin.default, _colorMixin.default, _proxiableMixin.default, {
    layout: _paperButton.default,
    tagName: 'button',
    classNames: ['md-default-theme', 'md-button'],
    raised: false,
    iconButton: false,
    fab: (0, _computed.reads)('mini'),
    // circular button
    mini: false,
    type: 'button',
    href: null,
    target: null,
    attributeBindings: ['type', 'href', 'target', 'title', 'download', 'rel'],
    classNameBindings: ['raised:md-raised', 'iconButton:md-icon-button', 'fab:md-fab', 'mini:md-mini'],
    init() {
      this._super(...arguments);
      if (this.href) {
        this.setProperties({
          tagName: 'a',
          type: null
        });
      }
    },
    click(e) {
      (0, _invokeAction.invokeAction)(this, 'onClick', e);
      // Prevent bubbling, if specified. If undefined, the event will bubble.
      return this.bubbles;
    }
  });
});