define("ember-paper/templates/components/paper-speed-dial-actions", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "x0kDaRKJ",
    "block": "[[[18,1,[[28,[37,1],null,[[\"action\"],[[50,\"paper-speed-dial-actions-action\",0,null,[[\"speedDial\"],[[33,3]]]]]]]]]],[\"&default\"],false,[\"yield\",\"hash\",\"component\",\"speedDial\"]]",
    "moduleName": "ember-paper/templates/components/paper-speed-dial-actions.hbs",
    "isStrictMode": false
  });
});