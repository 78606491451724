define("ember-paper/templates/components/paper-sidenav", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "Udt7pUdF",
    "block": "[[[41,[51,[33,1]],[[[1,\"  \"],[8,[39,2],null,[[\"@opaque\",\"@class\",\"@onClick\"],[true,\"md-sidenav-backdrop\",[28,[37,3],[[30,0],\"onBackdropTap\"],null]]],null],[1,\"\\n\"]],[]],null],[1,\"\\n\"],[8,[39,4],[[17,1]],[[\"@class\",\"@name\",\"@position\",\"@lockedOpen\",\"@closed\",\"@closeOnClick\",\"@onToggle\"],[[99,5,[\"@class\"]],[99,6,[\"@name\"]],[99,7,[\"@position\"]],[99,8,[\"@lockedOpen\"]],[99,1,[\"@closed\"]],[99,9,[\"@closeOnClick\"]],[28,[37,3],[[30,0],\"onToggle\"],null]]],[[\"default\"],[[[[1,\"\\n  \"],[18,2,null],[1,\"\\n\"]],[]]]]],[1,\"\\n\"]],[\"&attrs\",\"&default\"],false,[\"unless\",\"closed\",\"paper-backdrop\",\"action\",\"paper-sidenav-inner\",\"class\",\"name\",\"position\",\"lockedOpen\",\"closeOnClick\",\"yield\"]]",
    "moduleName": "ember-paper/templates/components/paper-sidenav.hbs",
    "isStrictMode": false
  });
});