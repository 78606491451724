define("ember-paper/components/paper-subheader", ["exports", "@ember/component", "ember-paper/templates/components/paper-subheader"], function (_exports, _component, _paperSubheader) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /**
   * @module ember-paper
   */
  /**
   * @class PaperSubheader
   * @extends Ember.Component
   */
  var _default = _exports.default = _component.default.extend({
    layout: _paperSubheader.default,
    tagName: 'h2',
    classNames: ['md-subheader']
  });
});