define("ember-paper/components/paper-select/component", ["exports", "@ember/component", "ember-paper/components/paper-select/template", "@ember-decorators/component", "@ember/object", "@ember/object/computed", "ember-paper/mixins/child-mixin", "ember-paper/mixins/validation-mixin", "ember-paper/utils/clamp"], function (_exports, _component, _template, _component2, _object, _computed, _childMixin, _validationMixin, _clamp) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _dec4, _class, _class2, _descriptor, _descriptor2;
  function _initializerDefineProperty(e, i, r, l) { r && Object.defineProperty(e, i, { enumerable: r.enumerable, configurable: r.configurable, writable: r.writable, value: r.initializer ? r.initializer.call(l) : void 0 }); }
  function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
  function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
  function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }
  function _applyDecoratedDescriptor(i, e, r, n, l) { var a = {}; return Object.keys(n).forEach(function (i) { a[i] = n[i]; }), a.enumerable = !!a.enumerable, a.configurable = !!a.configurable, ("value" in a || a.initializer) && (a.writable = !0), a = r.slice().reverse().reduce(function (r, n) { return n(i, e, r) || r; }, a), l && void 0 !== a.initializer && (a.value = a.initializer ? a.initializer.call(l) : void 0, a.initializer = void 0), void 0 === a.initializer && (Object.defineProperty(i, e, a), a = null), a; }
  function _initializerWarningHelper(r, e) { throw Error("Decorating class property failed. Please ensure that transform-class-properties is enabled and runs after the decorators transform."); }
  const SELECT_EDGE_MARGIN = 8;
  function getOffsetRect(node) {
    return node ? {
      left: node.offsetLeft,
      top: node.offsetTop,
      width: node.offsetWidth,
      height: node.offsetHeight
    } : {
      left: 0,
      top: 0,
      width: 0,
      height: 0
    };
  }
  let PaperSelect = (_dec = (0, _component2.tagName)(''), _dec2 = (0, _component2.layout)(_template.default), _dec3 = (0, _computed.and)('isInvalid', 'isTouched'), _dec4 = (0, _computed.and)('isFocused', 'selected'), _dec(_class = _dec2(_class = (_class2 = class PaperSelect extends _component.default.extend(_validationMixin.default, _childMixin.default) {
    constructor(...args) {
      super(...args);
      _defineProperty(this, "validationProperty", 'selected');
      _defineProperty(this, "isTouched", false);
      _defineProperty(this, "isFocused", false);
      _initializerDefineProperty(this, "isInvalidAndTouched", _descriptor, this);
      _initializerDefineProperty(this, "isFocusedAndSelected", _descriptor2, this);
    }
    didReceiveAttrs() {
      super.didReceiveAttrs(...arguments);
      this.notifyValidityChange();
    }
    close() {
      this.didAnimateScale = false;
      this.set('isTouched', true);
      this.notifyValidityChange();
    }
    open() {
      this.didAnimateScale = false;
      this.notifyValidityChange();
    }
    focus() {
      this.set('isFocused', true);
    }
    blur() {
      this.set('isFocused', false);
    }
    calculatePosition(trigger, content) {
      let opts = {
        target: trigger,
        parent: document.body,
        selectEl: content.querySelector('md-select-menu'),
        contentEl: content.querySelector('md-content')
      };
      let containerNode = content;
      let targetNode = opts.target.firstElementChild; // target the label
      let parentNode = opts.parent;
      let selectNode = opts.selectEl;
      let contentNode = opts.contentEl;
      let parentRect = parentNode.getBoundingClientRect();
      let targetRect = targetNode.getBoundingClientRect();
      let shouldOpenAroundTarget = false;
      let bounds = {
        left: parentRect.left + SELECT_EDGE_MARGIN,
        top: SELECT_EDGE_MARGIN,
        bottom: parentRect.height - SELECT_EDGE_MARGIN,
        right: parentRect.width - SELECT_EDGE_MARGIN
      };
      let spaceAvailable = {
        top: targetRect.top - bounds.top,
        left: targetRect.left - bounds.left,
        right: bounds.right - (targetRect.left + targetRect.width),
        bottom: bounds.bottom - (targetRect.top + targetRect.height)
      };
      let maxWidth = parentRect.width - SELECT_EDGE_MARGIN * 2;
      let selectedNode = selectNode.querySelector('md-option[selected]');
      let optionNodes = selectNode.getElementsByTagName('md-option');
      let optgroupNodes = selectNode.getElementsByTagName('md-optgroup');
      let centeredNode, left, top, transformOrigin;

      // If a selected node, center around that
      if (selectedNode) {
        centeredNode = selectedNode;
        // If there are option groups, center around the first option group
      } else if (optgroupNodes.length) {
        centeredNode = optgroupNodes[0];
        // Otherwise, center around the first optionNode
      } else if (optionNodes.length) {
        centeredNode = optionNodes[0];
        // In case there are no options, center on whatever's in there... (eg progress indicator)
      } else {
        centeredNode = contentNode.firstElementChild || contentNode;
      }
      if (contentNode.offsetWidth > maxWidth) {
        contentNode.style['max-width'] = `${maxWidth}px`;
      }
      if (shouldOpenAroundTarget) {
        contentNode.style['min-width'] = `${targetRect.width}px`;
      }

      // Remove padding before we compute the position of the menu

      let focusedNode = centeredNode;
      if ((focusedNode.tagName || '').toUpperCase() === 'MD-OPTGROUP') {
        focusedNode = optionNodes[0] || contentNode.firstElementChild || contentNode;
        centeredNode = focusedNode;
      }

      // Get the selectMenuRect *after* max-width is possibly set above
      containerNode.style.display = 'block';
      let selectMenuRect = selectNode.getBoundingClientRect();
      let centeredRect = getOffsetRect(centeredNode);
      if (centeredNode) {
        let centeredStyle = window.getComputedStyle(centeredNode);
        centeredRect.paddingLeft = parseInt(centeredStyle.paddingLeft, 10) || 0;
        centeredRect.paddingRight = parseInt(centeredStyle.paddingRight, 10) || 0;
      }

      // Get scrollHeight/offsetHeight *after* container is set with display:block
      let isScrollable = contentNode.scrollHeight > contentNode.offsetHeight;
      if (isScrollable) {
        let scrollBuffer = contentNode.offsetHeight / 2;
        contentNode.scrollTop = centeredRect.top + centeredRect.height / 2 - scrollBuffer;
        if (spaceAvailable.top < scrollBuffer) {
          contentNode.scrollTop = Math.min(centeredRect.top, contentNode.scrollTop + scrollBuffer - spaceAvailable.top);
        } else if (spaceAvailable.bottom < scrollBuffer) {
          contentNode.scrollTop = Math.max(centeredRect.top + centeredRect.height - selectMenuRect.height, contentNode.scrollTop - scrollBuffer + spaceAvailable.bottom);
        }
      }
      if (shouldOpenAroundTarget) {
        left = targetRect.left;
        top = targetRect.top + targetRect.height;
        transformOrigin = '50% 0';
        if (top + selectMenuRect.height > bounds.bottom) {
          top = targetRect.top - selectMenuRect.height;
          transformOrigin = '50% 100%';
        }
      } else {
        left = targetRect.left + centeredRect.left - centeredRect.paddingLeft + 2;
        top = Math.floor(targetRect.top + targetRect.height / 2 - centeredRect.height / 2 - centeredRect.top + contentNode.scrollTop) + 2;
        transformOrigin = `${centeredRect.left + targetRect.width / 2}px
        ${centeredRect.top + centeredRect.height / 2 - contentNode.scrollTop}px 0px`;
        containerNode.style.minWidth = `${targetRect.width + centeredRect.paddingLeft + centeredRect.paddingRight}px`;
      }
      let containerRect = containerNode.getBoundingClientRect();
      let dropdownTop = (0, _clamp.default)(bounds.top, top, bounds.bottom - containerRect.height);
      let dropdownLeft = (0, _clamp.default)(bounds.left, left, bounds.right - containerRect.width);
      let scaleX = Math.min(targetRect.width / selectMenuRect.width, 1.0);
      let scaleY = Math.min(targetRect.height / selectMenuRect.height, 1.0);
      let style = {
        top: dropdownTop,
        left: dropdownLeft,
        // Animate a scale out if we aren't just repositioning
        transform: !this.didAnimateScale ? `scale(${scaleX}, ${scaleY})` : undefined,
        'transform-origin': transformOrigin
      };
      this.didAnimateScale = true;
      return {
        style,
        horizontalPosition: '',
        verticalPosition: ''
      };
    }
  }, (_descriptor = _applyDecoratedDescriptor(_class2.prototype, "isInvalidAndTouched", [_dec3], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _descriptor2 = _applyDecoratedDescriptor(_class2.prototype, "isFocusedAndSelected", [_dec4], {
    configurable: true,
    enumerable: true,
    writable: true,
    initializer: null
  }), _applyDecoratedDescriptor(_class2.prototype, "close", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "close"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "open", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "open"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "focus", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "focus"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "blur", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "blur"), _class2.prototype), _applyDecoratedDescriptor(_class2.prototype, "calculatePosition", [_object.action], Object.getOwnPropertyDescriptor(_class2.prototype, "calculatePosition"), _class2.prototype)), _class2)) || _class) || _class);
  var _default = _exports.default = PaperSelect;
});