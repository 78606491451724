define("ember-paper/templates/components/paper-form", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "5JJmHq88",
    "block": "[[[18,1,[[28,[37,1],null,[[\"isValid\",\"isInvalid\",\"isTouched\",\"isInvalidAndTouched\",\"input\",\"submit-button\",\"select\",\"autocomplete\",\"onSubmit\"],[[33,2],[33,3],[33,4],[33,5],[50,[33,7],0,null,[[\"parentComponent\",\"onValidityChange\"],[[30,0],[28,[37,8],[[30,0],\"onValidityChange\"],null]]]],[50,[33,9],0,null,[[\"type\"],[\"submit\"]]],[50,[33,10],0,null,[[\"parentComponent\",\"onValidityChange\"],[[30,0],[28,[37,8],[[30,0],\"onValidityChange\"],null]]]],[50,[33,11],0,null,[[\"parentComponent\",\"onValidityChange\"],[[30,0],[28,[37,8],[[30,0],\"onValidityChange\"],null]]]],[28,[37,8],[[30,0],\"onSubmit\"],null]]]]]],[1,\"\\n\"]],[\"&default\"],false,[\"yield\",\"hash\",\"isValid\",\"isInvalid\",\"isTouched\",\"isInvalidAndTouched\",\"component\",\"inputComponent\",\"action\",\"submitButtonComponent\",\"selectComponent\",\"autocompleteComponent\"]]",
    "moduleName": "ember-paper/templates/components/paper-form.hbs",
    "isStrictMode": false
  });
});