define("ember-paper/templates/components/paper-subheader", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)({
    "id": "m8qYqyGn",
    "block": "[[[10,0],[14,0,\"md-subheader-inner\"],[12],[1,\"\\n  \"],[10,0],[14,0,\"md-subheader-content\"],[12],[1,\"\\n    \"],[18,1,null],[1,\"\\n  \"],[13],[1,\"\\n\"],[13]],[\"&default\"],false,[\"yield\"]]",
    "moduleName": "ember-paper/templates/components/paper-subheader.hbs",
    "isStrictMode": false
  });
});